import css from "../scss/style.scss"
import cssDark from "../scss/style.dark.scss"

import fullcalendarCss from "./plugins/custom/fullcalendar/fullcalendar.bundle.css"
import datatablesCss from "./plugins/custom/datatables/datatables.bundle.css"
import pluginsCss from "./plugins/global/plugins.bundle.css"

const $ = require("jquery")

import Swiper, { Navigation, Pagination } from "swiper"
import "swiper/swiper-bundle.css"
Swiper.use([Navigation, Pagination])

function docReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}
docReady(() => {
  var loadingTarget = document.querySelector("#loadingTarget")
  setTimeout(() => {
    loadingTarget.classList.remove("showed")
  }, 250)
  const beastSwiperF = document.querySelector(".js-beast-swiper")
  if (beastSwiperF) {
    const beastSwiper = new Swiper(".js-beast-swiper", {
      direction: "horizontal",
      observeParents: true,
      observer: true,
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      simulateTouch: false,
      onlyExternal: true,
      noSwiping: true,
      allowTouchMove: false,
      pagination: {
        el: ".js-beast-swiper .swiper-pagination",
      },

      navigation: {
        nextEl: ".js-beast-swiper-next",
        prevEl: ".js-beast-swiper-prev",
      },
    })
    beastSwiper.on("slideChange", function () {
      if (this.realIndex == 0) {
        $(".beast-modal__buttons").addClass("is-disabled")
      } else {
        $(".beast-modal__buttons").removeClass("is-disabled")
      }
      if (
        this.realIndex ===
        document.querySelectorAll(".swiper-slide").length - 1
      ) {
        $(".js-beast-modal-close").removeClass("d-none")
        $(".js-beast-swiper-next").addClass("d-none")
      } else {
        $(".js-beast-modal-close").addClass("d-none")
        $(".js-beast-swiper-next").removeClass("d-none")
      }
    })

    var myModal = document.getElementById("beast-modal")

    myModal.addEventListener("hide.bs.modal", function (event) {
      setTimeout(() => {
        beastSwiper.slideTo(0)
      }, 150)
    })
  }

  var linkCss = document.querySelectorAll('link[rel="stylesheet"]')

  let darkMode = localStorage.getItem("darkMode")
  const darkModeToggle = document.querySelector(".js-dark-mode")
  const enableDarkMode = () => {
    document.body.classList.add("darkmode")
    localStorage.setItem("darkMode", "enabled")
    linkCss.forEach((el) => {
      let getCssName = el.getAttribute("href").split("css/").at(-1)
      if (getCssName === "style.css") {
        el.remove()
      }
      if (getCssName === "style.dark.css") {
        $("head").append(el)
      }
    })
  }
  const disableDarkMode = () => {
    document.body.classList.remove("darkmode")
    localStorage.setItem("darkMode", null)
    linkCss.forEach((el) => {
      let getCssName = el.getAttribute("href").split("css/").at(-1)
      if (getCssName === "style.dark.css") {
        el.remove()
      }
      if (getCssName === "style.css") {
        $("head").append(el)
      }
    })
  }
  if (darkMode === "enabled") {
    enableDarkMode()
  } else {
    disableDarkMode()
  }
  darkModeToggle.addEventListener("click", () => {
    darkMode = localStorage.getItem("darkMode")

    if (darkMode !== "enabled") {
      enableDarkMode()
    } else {
      disableDarkMode()
    }
    var loadingTarget = document.querySelector("#loadingTarget")
    loadingTarget.classList.add("showed")
    setTimeout(() => {
      loadingTarget.classList.remove("showed")
    }, 550)
  })
})
